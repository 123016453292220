<template>
  <div class="import-container">
    <div class="header">
      <v-button text="返回" @click="previous"></v-button>
    </div>
    <import-template :uploadUrl="uploadUrl" :downloadUrl="downloadUrl">
      <div slot="remark">
        <p>1、用户手机号、房号、车位在后台中已存在，才可录入；</p>
        <p>2、模板中红色字体部分为必填项，灰色部分为非必填项；</p>
        <p>3、最大支持10MB的excel文件，超过请拆分导入。</p>
        <div>
          <label style="position: absolute;left: 90px;font-weight: 700;">字段说明：</label>
          <p>1、车位所属项目：必填，且为项目信息表中已有的项目名称</p>
          <p>2、车位所属苑：非必填</p>
          <p>3、车位所属幢：必填</p>
          <p>4、车位所属单元：非必填</p>
          <p>5、车位所属室：必填</p>
          <p>6、用户手机号：必填</p>
          <p>7、用户所属项目：必填，必须是项目信息表中已有的项目名称</p>
          <p>8、苑：非必填</p>
          <p>9、幢：必填</p>
          <p>10、单元：非必填</p>
          <p>11、室：非必填</p>
          <p>12、状态：必填，单选：正常、关闭</p>
          <p>13、类型：必填，单选：租、售</p>
          <p>14、租期（开始时间）:当类型为“租”时，必填, 格式：YY-MM-DD</p>
          <p>15、租期（结束时间）:当类型为“租”时，必填, 格式：YY-MM-DD</p>
        </div>
      </div>
    </import-template>
  </div>
</template>
<script>
import { ImportTemplate } from 'components/bussiness/index'
import { uploadUrl } from './api'
export default {
  components: {
    ImportTemplate
  },
  data () {
    return {
      uploadUrl,
      downloadUrl: '/static/excel/车位导入模板.xlsx'
    }
  },
  created () {
    this.$setBreadcrumb('导入')
  },
  methods: {
    previous () {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang="scss" scoped>
  .import-container {
    text-align: left;
    background: #fff;
  }
  .header {
    position: absolute;
    top: 20px;
    right: 20px;
    :not(:last-child) {
      margin-right: 8px;
    }
  }
</style>
